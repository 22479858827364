import React from 'react';
import ChatFrontend from './ChatFrontend';

function App() {
  return (
    <div className="App">
      <ChatFrontend />
    </div>
  );
}

export default App;