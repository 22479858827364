import React, { useState } from 'react';

const ChatFrontend = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [selectedAI, setSelectedAI] = useState({ model: 'Zappy-Name', from: 'Freezy' });
  const [savedChats, setSavedChats] = useState(['Chats Coming Soon.']); // Mock data for saved chats

  const aiOptions = [
    { from: 'Freezy', model: 'Zappy-Name', description: 'Zappy ist eine KI die von Freezy Trainiert wurde. Es ist noch in der Alpha' },
    { from: 'Freezy', model: 'Zappy-Big', description: 'Ein Model was auf einem größerem Datansatz trainiert wurde. Ist aber immernoch kacke.' },
    { from: 'BlackForest', model: 'FLux 1', description: 'Kann Bilder für dich generieren.' }
	/*{ from: 'OpenAI', model: 'GPT-4o', description: 'OpenAIs angeblich bestes Model. Gut zum Bilder analysieren.' },
	{ from: 'OpenAI', model: 'Dall-E-3', description: 'OpenAIs bild generierungs KI.' },
    { from: 'OpenAI', model: 'GPT-4-Turbo', description: 'OpenAIs wirklich bestes Model. Gut für komplexere Aufgaben.' },
    { from: 'OpenAI', model: 'GPT-4', description: 'OpenAIs teuerstes Model. NICHT BENUTZEN' },
    { from: 'OpenAI', model: 'GPT-3.5-Turbo', description: 'OpenAIs schnellstes Model. Gut für einfache Aufgaben.' },
    { from: 'Anthropic', model: 'Claude-3-Opus', description: 'Anthropics bestes Model nach Sonnet 3.5.' },
    { from: 'Anthropic', model: 'Claude-3.5-Sonnet', description: 'Derzeit bestes model auf dem markt.' },
    { from: 'Anthropic', model: 'Claude-3-Haiku', description: 'Anthropics version von GPT-3.5-Tubro' }*/
  ];
  
  function addTestModel(model) {
    aiOptions.push({ from: 'Test', model: model, description: 'This is a custom added model. Only those who knows, knows.' });
  }

  const handleSendMessage = async () => {
    if (inputText.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: inputText, sender: 'user' }]);
    setInputText('');

    try {
      const response = await fetch('https://zappyapi.freezy.xyz/execute', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: inputText, name: selectedAI.model, from: selectedAI.from }),
        mode: 'cors'
      });

      if (!response.ok) throw new Error('API call failed');

      const data = await response.json();
      setMessages(prevMessages => [...prevMessages, { text: data.reply, sender: 'api' }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [...prevMessages, { text: 'Error: Could not get response', sender: 'error' }]);
    }
  };

  const handleAISelection = (e) => {
    const selectedModel = e.target.value;
    const selectedAIInfo = aiOptions.find(ai => ai.model === selectedModel);
    setSelectedAI(selectedAIInfo);
  };

  return (
    <div className="flex h-screen bg-gray-900 text-gray-100">
      {/* Sidebar for saved chats */}
      <div className="w-64 bg-gray-800 p-4">
        <h2 className="text-xl font-bold mb-4">Saved Chats</h2>
        <ul>
          {savedChats.map((chat, index) => (
            <li key={index} className="mb-2 cursor-pointer hover:bg-gray-700 p-2 rounded">{chat}</li>
          ))}
        </ul>
      </div>

      {/* Main chat area */}
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <header className="bg-gray-800 p-4">
          <div className="flex items-center mb-2">
            <select
              value={selectedAI.model}
              onChange={handleAISelection}
              className="w-[250px] bg-gray-700 text-gray-100 border-gray-600 rounded p-1 text-xs"
            >
              {aiOptions.map((option) => (
                <option key={option.model} value={option.model}>{option.model}</option>
              ))}
            </select>
            <h1 className="text-2xl font-bold text-gray-100 ml-4">
              Zappy AI
            </h1>
          </div>
          {selectedAI && (
            <div className="text-sm text-gray-300">
              From: {selectedAI.from} | Model: {selectedAI.model}
            </div>
          )}
        </header>

        {/* Chat messages */}
        <div className="flex-1 overflow-auto p-4 space-y-4">
          {messages.map((message, index) => (
            <div key={index} className={`p-3 rounded-lg ${message.sender === 'user' ? 'ml-auto bg-blue-900' : 'bg-gray-800'} max-w-[70%]`}>
              {message.text}
            </div>
          ))}
        </div>

        {/* Input area */}
        <div className="p-4 bg-gray-800">
          <div className="flex">
            <input
              type="text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              placeholder="Type your message..."
              className="flex-grow bg-gray-700 text-gray-100 border-gray-600 focus:border-blue-500 p-2 rounded-l"
            />
            <button 
              onClick={handleSendMessage} 
              className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-r"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatFrontend;